import {navigateTo, nextTick, useCookie, useRuntimeConfig, useState} from "#imports";
import {CookieRef} from "#app";
import {Auth0Interface, Auth0User} from "~/src/auth/types";
import {Ref} from "@vue/reactivity";
import {WebAuth} from "auth0-js";

export const useAuth0BearerToken = (): CookieRef<string> => useCookie<string>('HB_TOKEN')

const useAuth0User = (): Ref<Auth0User | null> => useState('HB_USER', () => null);
const route = useRoute();
export const useAuth0 = (): Auth0Interface => {
    const config = useRuntimeConfig();
    const user = useAuth0User();
    const token = useAuth0BearerToken();

    const setUser = (value: Auth0User | null, redirectTo: string): void => {
        user.value = value;
    }

    const getUser = async (redirectTo: string): Promise<Ref<Auth0User | null>> => {
        console.log('get user',user.value,token.value);
        if (!user.value && !!token.value) {
            try {
                const userinfo : Auth0User = await $fetch('/userinfo', {
                    baseURL: config.public.auth0Domain,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token.value}`,
                    }
                });
                console.log('userinfo abgeschlossen',userinfo);

                setUser(userinfo,redirectTo);
            } catch (e: any) {
                setUser(null,redirectTo);
            }
        }

        return user;
    }

    const logout = async (redirectTo: string): Promise<void> => {
        console.log('Nutzer wird ausgeloggt...');

        // Stelle sicher, dass Logout nur einmal passiert
        if (sessionStorage.getItem('logoutInProgress')) {
            console.log('Logout bereits in Bearbeitung, breche ab');
            return;
        }

        sessionStorage.setItem('logoutInProgress', 'true');

        useAuth0BearerToken().value = null;

        localStorage.setItem('allowSilentLogin', 'true');
        sessionStorage.removeItem('silentLoginAttempted');

        const redirectUrl = new URL('/v2/logout', config.public.auth0Domain);
        redirectUrl.searchParams.set('client_id', config.public.auth0Client);
        redirectUrl.searchParams.set('returnTo', redirectTo);

        await nextTick(() => navigateTo(redirectUrl.href, {external: true}));
    }

    const silentLogin = async (redirectTo: string): Promise<void> => {
        console.log('Try silent login');

        if (sessionStorage.getItem('silentLoginAttempted') && localStorage.getItem('allowSilentLogin') !== 'true') {
            console.log('Silent login already attempted, aborting retry');
            return;
        }

        const webAuth = new WebAuth({
            domain: config.public.auth0Domain,
            clientID: config.public.auth0Client,
            scope: 'openid email profile user_metadata',
            responseType: 'token',
            responseMode: 'fragment',
        });

        webAuth.checkSession({
            audience: config.public.auth0Audience,
            redirectUri: redirectTo
        }, (err, authResult) => {
            if (err) {
                console.log('Silent login failed, trying full login:', err);

                // Setze Flag, um Endlosschleife zu vermeiden
                sessionStorage.setItem('silentLoginAttempted', 'true');
                localStorage.removeItem('allowSilentLogin');

                webAuth.authorize({
                    prompt: 'none',
                    audience: config.public.auth0Audience,
                    redirectUri: redirectTo,
                });
            } else {
                console.log('Silent login successful:', authResult);

                useAuth0BearerToken().value = authResult.accessToken;

                sessionStorage.removeItem('silentLoginAttempted');
                localStorage.removeItem('allowSilentLogin');

                navigateTo(redirectTo, {external: true});
            }
        });
    };

    const authorize = (redirectTo: string, email?: string): void => {
        const webAuth = new WebAuth({
            domain: config.public.auth0Domain,
            clientID: config.public.auth0Client,
            scope: 'openid email profile user_metadata',
            responseType: 'token',
            responseMode: 'fragment',
        });

        return webAuth.authorize({
            audience: config.public.auth0Audience,
            redirectUri: redirectTo,
            login_hint: email,
            connection: 'email',
        })
    }

    return {
        getUser,
        setUser,
        logout,
        authorize,
        silentLogin,
    }
}
